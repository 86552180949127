import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import audioCardio from '../../assets/images/audio-cardio/audio-cardio-banner.webp';
import audioCardioOne from '../../assets/images/audio-cardio/audio-cardio-1.webp';
import audioCardioTwoA from '../../assets/images/audio-cardio/audio-cardio-2-a.webp';
import audioCardioTwoB from '../../assets/images/audio-cardio/audio-cardio-2-b.webp';
import audioCardioTwoC from '../../assets/images/audio-cardio/audio-cardio-2-c.webp';
import audioCardioThreeA from '../../assets/images/audio-cardio/audio-cardio-3-aa.webp';
import audioCardioThreeB from '../../assets/images/audio-cardio/audio-cardio-3-bb.webp';
import audioCardioFourA from '../../assets/images/audio-cardio/audio-cardio-4-aa.webp';
import audioCardioFourB from '../../assets/images/audio-cardio/audio-cardio-4-bb.webp';
import audioCardioFiveA from '../../assets/images/audio-cardio/audio-cardio-5-a.webp';
import audioCardioFiveB from '../../assets/images/audio-cardio/audio-cardio-5-b.webp';
import audioCardioFiveC from '../../assets/images/audio-cardio/audio-cardio-5-c.webp';
import audioCardioFiveD from '../../assets/images/audio-cardio/audio-cardio-5-d.webp';
import audioCardioFiveE from '../../assets/images/audio-cardio/audio-cardio-5-e.webp';
import audioCardioFiveF from '../../assets/images/audio-cardio/audio-cardio-5-f.webp';
import audioCardioFiveG from '../../assets/images/audio-cardio/audio-cardio-5-g.webp';
import audioCardioFiveH from '../../assets/images/audio-cardio/audio-cardio-5-h.webp';
import audioCardioSixA from '../../assets/images/audio-cardio/audio-cardio-6-a.webp';
import audioCardioSixB from '../../assets/images/audio-cardio/audio-cardio-6-b.webp';
import audioCardioSixC from '../../assets/images/audio-cardio/audio-cardio-6-c.webp';
import audioCardioSixD from '../../assets/images/audio-cardio/audio-cardio-6-d.webp';
import audioCardioSixE from '../../assets/images/audio-cardio/audio-cardio-6-e.webp';
import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';

class Swvl extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container jobbox-section-portfolio">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  Sound Therapy App to Strengthen Hearing & Relieve Tinnitus
                </div>
                <div className="subtitle section-margin">
                  Building on proprietary technology to develop an evidence-based mobile app that
                  delivers inaudible sound therapies
                </div>
                <div className="client-title section-margin">
                  CLIENT | <span style={{color: '#0076B1'}}>AUDIOCARDIO</span>
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Develop and provide an easily accessible and affordable solution for people
                      with hearing loss and tinnitus.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Built new features for the existing iOS app, created an Android version from
                      scratch, and updated the backend to bring everything in sync.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    <p className="desc">
                      The app has received 400+ 5 start reviews, with over 70% of customers who used
                      the app for a minimum of 30 sessions reporting positive changes to their
                      hearing or tinnitus.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    {' '}
                    <a
                      className="press-desc"
                      href="https://labusinessjournal.com/featured/app-uses-sound-therapy-to-treat-hearing-loss/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      App Uses Sound Therapy to Treat Hearing Loss
                    </a>{' '}
                    – Los Angeles Business Journal
                    <br />
                    <a
                      className="press-desc"
                      href="https://www.hearingtracker.com/podcast/hearing-loss-therapies-backed-by-science"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Hearing Loss Therapies Backed By Science
                    </a>{' '}
                    – Hearing Tracker
                  </p>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/awIQDF5TZ5I"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto ">
                  <div className="text-with-image-container section-margin">
                    <div className="image-container text-center">
                      <img src={audioCardioOne} alt="audioCardio" style={{maxHeight: '450px'}} />
                    </div>
                    <div className="subheading-big">
                      Client&apos;s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        href="https://audiocardio.com/"
                        style={{textDecoration: 'underline'}}
                        target="_blank"
                        rel="noreferrer"
                      >
                        AudioCardio
                      </a>{' '}
                      is a digital health and wellness company that has developed technology to
                      address sensorineural hearing loss. Their clinically proven Threshold Sound
                      Conditioning (TSC) technology generates personalized sound therapies just
                      below the audible level to stimulate cells inside your ear. These inaudible
                      sound therapies are delivered via their mobile app and designed to maintain
                      and strengthen your hearing while providing relief from tinnitus.
                    </p>
                    <br />

                    <p className="description">
                      In order to determine these personalized “silent” therapies (barely audible
                      sounds of a certain volume and frequency), users have to undergo an initial
                      hearing assessment. It consists of ten steps with five different levels of
                      frequency for both ears in turn. At each step (or frequency level e.g.
                      1000Hz), the user moves the volume slider up and down to find the cusp of
                      sound or the barely audible level and then set it there. These values are then
                      fed into an algorithm to determine the user’s initial hearing score as well as
                      personalized sound therapy.
                    </p>
                    <br />
                    <p className="description">
                      When AudioCardio first approached Tintash, they already had an iOS app
                      published on the App Store. They wanted us to build new features and add
                      greater functionality to their existing app, plus create a similar Android
                      application from scratch.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How has Tintash delivered?</div>
                    <p className="description">
                      We have served as the development partner of AudioCardio since 2019. During
                      this span, our work with this client has progressed in several discrete
                      phases.
                    </p>
                    <br />
                    <div className="subheading-big">Phase 1</div>
                    <p className="description">
                      We worked on several feature sets simultaneously during this phase. To begin
                      with, the client wanted to enable its users to play sound therapies at their
                      desired frequencies. We updated the logic to include a feature containing a
                      switch called “frequency targeting”. Turning on this switch would prompt users
                      to select frequencies of their choice.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-3 col-6">
                      <img
                        className="w-100 sub-pic mt-sm-3"
                        src={audioCardioTwoA}
                        alt="audioCardio"
                        style={{aspectRatio: '2/3.5'}}
                      />
                    </div>
                    <div className="col-lg-3 col-6  mt-sm-3">
                      <img
                        className="w-100 sub-pic"
                        src={audioCardioTwoB}
                        alt="audioCardio "
                        style={{aspectRatio: '2/3.5'}}
                      />
                    </div>
                    <div className="col-lg-3 col-6  mt-sm-3">
                      <img
                        className="w-100 sub-pic"
                        src={audioCardioTwoC}
                        alt="audioCardio"
                        style={{aspectRatio: '2/3.5'}}
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      The AudioCardio app prompted users to set their device volume to 60% in order
                      to accurately personalize and play their sound therapies. Users had complained
                      that while using the optional Sound Therapy with Music, setting their hardware
                      volume at 60% was too loud. Since both the volumes – music and sound therapy –
                      were connected, lowering the former would make sound therapy ineffective since
                      it needed to be played at the barely audible level.
                    </p>
                    <br />
                    <p className="description">
                      To work around this problem, we created a flow to determine users’ hearing
                      abilities. We included a “Pre Test” before the main hearing assessment to
                      determine whether a user had good or bad hearing. Based on those results, we
                      set their hardware volume to either 60% or 40% so that they could conveniently
                      listen to their music without disrupting the therapy. We also updated the
                      formula used in calculating users’ hearing scores to include the “Pre Test”
                      results as well.
                    </p>
                    <br />
                    <p className="description">
                      We also did some code cleanup and refactoring and updated third party
                      libraries. This helped reduce the size of the app which took a lot of time to
                      build and run back then and made it both space and time efficient. Some of the
                      other core features we worked on during this phase included reminder
                      notifications (for completing assessments and daily therapy sessions) and
                      generating an app link (shareable via other apps).
                    </p>
                  </div>
                  <div className="text-with-image-container section-margin">
                    <div className="image-container d-flex flex-wrap justify-content-center mt-3 mb-5">
                      <div className="col-lg-6 col-6 p-0">
                        <img
                          className="w-100 sub-pic "
                          style={{maxWidth: '100%'}}
                          src={audioCardioThreeA}
                          alt="audioCardio"
                        />
                      </div>
                      <div className="col-lg-6 col-6  p-0">
                        <img
                          className="w-100 sub-pic"
                          style={{maxWidth: '100%'}}
                          src={audioCardioThreeB}
                          alt="audioCardio"
                        />
                      </div>
                    </div>
                    <div className="subheading-big">Phase 2</div>
                    <p className="description">
                      During this period, we worked on a couple of animations to improve the user
                      experience. We introduced a sound wave animation that played during the
                      assessment and therapy to give users a feel of an audio playing. We also
                      introduced a custom circle meter/timer animation. This was a circle around the
                      therapy timer that filled with green to indicate how much therapy had been
                      used in a session.
                    </p>
                    <br />
                    <p className="description">
                      Beside these animations, we added a feature that allowed users to update their
                      profiles including age and email accounts within the app. We also enabled
                      users to rate the app inside the App Store. Lastly, we introduced new SDKs to
                      better understand the application’s  metrics and analytics for our client’s
                      marketing requirements.
                    </p>
                  </div>
                  <div className="text-with-image-container section-margin">
                    <div className="image-container-2 d-flex flex-wrap justify-content-center mt-3 mb-5">
                      <div className="col-lg-6 col-6 p-0">
                        <img className="w-100 sub-pic" src={audioCardioFourA} alt="audioCardio" />
                      </div>
                      <div className="col-lg-6 col-6 p-0">
                        <img className="w-100 sub-pic" src={audioCardioFourB} alt="audioCardio" />
                      </div>
                    </div>
                    <div className="subheading-big">Phase 3</div>
                    <p className="description">
                      In the latest phase, we worked on segmenting the app into two modes – Basic
                      and Pro. The client wanted to split the app into a Basic mode which came with
                      primary features, and a Pro mode which enabled advanced features. In order to
                      implement this, we updated the entire app structure. And while at it, our team
                      also updated AudioCardio’s existing purchase model to introduce these new
                      packages and various subscription tiers.{' '}
                    </p>
                    <br />
                    <p className="description">
                      One advanced feature that came with the Pro mode was the option to target a
                      frequency of choice. The client wanted to introduce six frequency programs
                      each targeting a different set of frequencies to run during the sound therapy
                      sessions. While there was one frequency program in the Basic mode, in the Pro
                      mode, users could choose any of the six frequency programs.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      To include these programs, we had to update the app’s existing base logic. It
                      required a separate UI for users to select their programs, each of which came
                      with a lot of detail and needed updated frequency and therapy settings
                      throughout the app. Our team created a clean and elaborate UI to allow users
                      to easily choose between the programs and incorporated the programs’ related
                      functionality within the app to ensure a smooth process.
                    </p>
                    <br />
                    <p className="description">
                      In addition to these programs, we updated the onboarding flow by adding new
                      screens so as to give a more detailed overview of the platform to new users.
                      We also brought both the iOS and Android apps up-to-date as per their latest
                      respective operating systems. We further removed the outdated libraries and
                      SDKs, improved the app’s installation and running processes, and fixed a
                      number of device and user-related issues.
                    </p>
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      The team at Tintash is thorough and efficient in their work. They can produce
                      a high-quality product while remaining affordable. Tintash is also proactive
                      and forward-thinking in their design choices. Customers can expect dedicated
                      workers who feel as if they are part of the team, even if working remotely.
                    </p>
                    <div className="d-flex mt-3">
                      <div className=" m-0 mt-auto mb-auto">
                        <p className="review-name mt-0">
                          Director, AudioCardio on{' '}
                          <a
                            href="https://clutch.co/profile/tintash?page=1#review-1305924"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Clutch
                          </a>
                        </p>
                      </div>
                    </div>

                    <br />
                    <br />
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How does the final product look?</div>
                    <p className="description">
                      This remains an ongoing engagement as we continue to work periodically with
                      AudioCardio on developing new feature sets. As of this writing, AudioCardio
                      has a fully functional and updated app with a polished UI, available on both
                      iOS and Android. The app has received great user feedback, with over 400
                      5-star reviews.
                    </p>
                    <br />
                    <p className="description">
                      The app quickly assesses your hearing and delivers a once a day, one hour
                      sound therapy that you can play passively in the background while you go about
                      your day. The therapy session works in any environment, loud or quiet. With
                      the optional Sound Therapy + Music feature, users can even listen to their
                      favorite music, podcasts, and audiobooks. They can connect to services like
                      Spotify and Apple Music within the app or open other apps while AudioCardio
                      continues to play in the background.
                    </p>
                    <br />
                    <p className="description">
                      Users are prompted to retake hearing assessments anytime their sound therapy
                      becomes audible. The app allows you to view your hearing assessment levels and
                      changes so you can measure the progress you’ve made over time. You can notice
                      significant results after only a few weeks of daily use. More than 70% of
                      customers who used the app for a minimum of 30 sessions have reported positive
                      changes to their hearing or tinnitus, although results may vary.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin col-12 mr-auto ml-auto">
                    <div className="image-description text-center mb-3 col-12">
                      AudioCardio: some in-app screens
                    </div>
                    <div className="col-6 mt-3" style={{maxWidth: '20%'}}>
                      <img className="w-100" src={audioCardioSixA} alt="bed bath and beyond" />
                    </div>
                    <div className="col-6 mt-3" style={{maxWidth: '20%'}}>
                      <img className="w-100" src={audioCardioSixB} alt="bed bath and beyond" />
                    </div>
                    <div className="col-6 mt-3" style={{maxWidth: '20%'}}>
                      <img className="w-100" src={audioCardioSixC} alt="bed bath and beyond" />
                    </div>
                    <div className="col-6 mt-3" style={{maxWidth: '20%'}}>
                      <img className="w-100" src={audioCardioSixD} alt="bed bath and beyond" />
                    </div>
                    <div className=" col-6 mt-3" style={{maxWidth: '20%'}}>
                      <img className="w-100" src={audioCardioSixE} alt="bed bath and beyond" />
                    </div>
                  </div>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/4vi_NJH7Lt4"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Swvl;
